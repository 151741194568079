import React from 'react';
import img from './../assets/img.png'



export default function SpacingGrid() {

  return (
    <div style={{textAlign: 'center'}}>     
        <img src={img} style={{height:'150px'}} alt="Thor-Tex"/>
      </div>
      
  )
  
}